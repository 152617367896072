/** @jsx jsx */
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { jsx, css, CacheProvider } from '@emotion/core';
import styled from '@emotion/styled';
import createCache from '@emotion/cache';
import { colors } from '@packages/fdpq-core/config/vars';
import { LoadingModal, usePagination, VehicleInfoArgo } from '@packages/fdpq-common';
import FlexBox from '@argo/principles/FlexBox';
import Pagination from '@argo/base-patterns/Pagination';
import Link from '@argo/ui-elements/Link';
import Button from '@argo/ui-elements/Button';
import Heading from '@argo/ui-elements/Heading';
import Paragraph from '@argo/ui-elements/Paragraph';
import ContentBlock from '@argo/principles/ContentBlock';
import Breakpoints from '@argo/principles/constants/breakpoints';
import Size from '@argo/principles/constants/size';
import { useAdaptiveRender } from '@argo/utils';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import { mapStateToProps, mapDispatchToProps } from '../containers/DealerSelector';
import DealerList from './DealerListArgo';
import useDealerValidation from './useDealerValidation';
import BlankDealerListArgo from './BlankDealerListArgo';
import { pageLimitDefault, printSelectedDealerInfo, getDealerDisplayList, updatePaginationAndButtonWidth } from './dealerListArgoHelpers';
import { getUrlHostName } from '../../fdpq-widget/utils/helperUtil';


/*
* This is an advance approach of Emotion Server Side Rendering by using CacheProvider.
* To prevent the issue related to the `:first-child` attribute in Pagination/NumberButton when complie with Emotion.
* For more details at:
* - https://emotion.sh/docs/ssr
* - https://github.com/emotion-js/emotion/issues/1105
* */
const emotionCache = createCache();
emotionCache.compat = true;

const paginationStyles = css`
  max-width: 410px;
`;

const PaginationContainer = styled.div`
${({ align }) => `
    display: flex;
    width: 100%;
    justify-content: ${align};
  `}
`;

const DealerSelectorArgo = ({
  dealerList,
  errorMessage,
  description,
  selectedDealers,
  initDealerSelectorPage,
  onNextPageSelected,
  onButtonPress,
  onClickChangeZip,
  onChangeZipSelected,
  listingTypeConfig,
  zipCode,
}) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const pages = Math.ceil(dealerList.length / pageLimitDefault);
  const { displayList } = usePagination({ dataList: dealerList, pageLimit: pageLimitDefault });
  const displaySelectedItems = useDealerValidation(displayList, selectedDealers);
  const [displayDealerList, setDisplayDealers] = useState(displaySelectedItems);
  const { width } = useAdaptiveRender();
  const hostName = getUrlHostName();
  const isKbb = hostName.includes(hostName);
  const isSmallOrMediumViewPort = width < Breakpoints.lg;
  const isSmallViewPort = width < Breakpoints.md;
  const isTradeIn = (listingTypeConfig.vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN);

  useEffect(() => {
    initDealerSelectorPage(selectedDealers, dealerList);
    fetchDealersData(); // If go to the page directly and wait dealers data
  }, [dealerList.length, displaySelectedItems, selectedDealers]);

  const EmptyComponent = styled.div`
    margin: 0 !important;
  `;

  const AlignedComponent = styled.div`
  ${({ align }) => `
    text-align: ${align};
    align-items: ${align};
  `}
`;

  const InlineHeading = styled(Heading)`
    display: inline-block;
  `;

  const InlineComponent = styled.div`
    display: inline-block;
  `;

  const ZipCodeWrapper = styled(InlineComponent)`
    margin-left: 24px;
  `;
  const ZipCodeComponent = () => (
    <ZipCodeWrapper>
      <Paragraph id="change-zip-button">
        <Link
          href="#"
          target="_self"
          type="regular"
          onClick={(e) => {
            e.preventDefault();
            onClickChangeZip();
            onChangeZipSelected(e);
          }}
        >
          Change ZIP Code
        </Link>
      </Paragraph>
    </ZipCodeWrapper>
  );

  const errorTextStyles = css`
    color: ${colors.global.red1};
  `;

  const loadingModalStyle = css`
    margin-top: -${Size.micro}px;
  `;

  const loadingModalSmallStyle = css`
    margin-top: -${Size.xs}px;
  `;

  const flexBoxMobileTablet = [
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '1 0 0',
    '1 0 100%',
  ];

  const flexBoxLarge = [
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '0 0 70%',
    '0 0 0',
    '0 0 30%',
  ];

  const flexBoxDesktop = [
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '0 0 60%',
    '0 0 10%',
    '0 0 30%',
  ];

  const fetchDealersData = () => {
    if (displaySelectedItems.length > 0 && selectedDealers.length > 0 && displayDealerList.length === 0) {
      setDisplayDealers(displaySelectedItems);
    }
  };

  const handlePageChange = (selected) => {
    setSelectedPage(selected);
    setDisplayDealers(getDealerDisplayList(selected, dealerList, selectedDealers));
  };

  updatePaginationAndButtonWidth(pages, flexBoxLarge, flexBoxDesktop);
  printSelectedDealerInfo(selectedDealers, selectedPage);

  return (
    <FlexBox
      alignItems="flex-start"
      default={flexBoxMobileTablet}
      xs={flexBoxMobileTablet}
      sm={flexBoxMobileTablet}
      md={flexBoxMobileTablet}
      lg={flexBoxLarge}
      xl={flexBoxDesktop}
      max={flexBoxDesktop}
      flexWrap="wrap"
      id="test-dealer-selector"
      horizontalSpacing={isSmallOrMediumViewPort ? 'sm' : 'md'}
    >
      <LoadingModal loadingSubscriptions={['getDealerList']} useArgoStyle={true} loadingModalStyle={isSmallOrMediumViewPort ? loadingModalSmallStyle : loadingModalStyle} />
      {isTradeIn
        ? <Fragment>
          <FlexBox
            alignItems="flex-start"
            horizontalSpacing='md'
            verticalSpacing="xs"
            flexWrap="wrap"
            default={['1 0 50%']}
            sm={['1 0 100%', '1 0 100%']}
            xs={['1 0 100%', '1 0 100%']}
          >
            <AlignedComponent align='left'>
              <VehicleInfoArgo id="test-voi-vehicleinfo" heading={isTradeIn ? 'VOI' : ''} />
            </AlignedComponent>
            {isTradeIn
              ? <AlignedComponent align={isSmallViewPort ? 'left' : 'right'}>
                <VehicleInfoArgo id="test-tradein-vehicleinfo" heading='TRADEIN' />
              </AlignedComponent>
              : ''
            }
          </FlexBox>
          <ContentBlock verticalSpacing="xs" horizontalSpacing={isSmallOrMediumViewPort ? 'sm' : 'md'}>
            <div>
              <InlineHeading
                headingName="ContentHeadingExtra"
                headingTag="h2"
                textTransformation={true}
                enableTitleCase={false}
                id="description-title"
              >
                {description.title}
              </InlineHeading>
              {isTradeIn
                ? <ZipCodeComponent />
                : ''
              }
            </div>
            <ContentBlock verticalSpacing="xs" horizontalSpacing={isSmallOrMediumViewPort ? 'sm' : 'md'}>
              <InlineComponent>
                <Paragraph
                  defaultMargin={true}
                  textAlign="left"
                  type="body"
                  id="description-subtitle"
                >
                  {description.text}
                </Paragraph>
              </InlineComponent>
              {isTradeIn ? ''
                : <ZipCodeComponent />
              }
            </ContentBlock>
            {errorMessage && (
              <Paragraph
                defaultMargin={true}
                textAlign="left"
                type="body"
                css={errorTextStyles}
              >
                {errorMessage}
              </Paragraph>
            )}
          </ContentBlock>
        </Fragment> : <Fragment>
          <ContentBlock verticalSpacing="xs" horizontalSpacing={isSmallOrMediumViewPort ? 'sm' : 'md'}>
            <div>
              <InlineHeading
                headingName="ContentHeadingExtra"
                headingTag="h2"
                textTransformation={true}
                enableTitleCase={false}
                id="description-title"
              >
                {description.text}
              </InlineHeading>
              {isTradeIn
                ? <ZipCodeComponent />
                : ''
              }
            </div>
            <ContentBlock verticalSpacing="xs" horizontalSpacing={isSmallOrMediumViewPort ? 'sm' : 'md'}>
              <InlineComponent>
                <Paragraph
                  defaultMargin={true}
                  textAlign="left"
                  type="body"
                  id="description-subtitle"
                >
                  {description.title}
                </Paragraph>
              </InlineComponent>
              {isTradeIn ? ''
                : <ZipCodeComponent />
              }
            </ContentBlock>
            {errorMessage && (
              <Paragraph
                defaultMargin={true}
                textAlign="left"
                type="body"
                css={errorTextStyles}
              >
                {errorMessage}
              </Paragraph>
            )}
          </ContentBlock>
          <FlexBox
            alignItems="flex-start"
            horizontalSpacing='md'
            verticalSpacing="xs"
            flexWrap="wrap"
            default={['1 0 50%']}
            sm={['1 0 100%', '1 0 100%']}
            xs={['1 0 100%', '1 0 100%']}
          >
            <AlignedComponent align='left'>
              <VehicleInfoArgo id="test-voi-vehicleinfo" heading={isTradeIn ? 'VOI' : ''} />
            </AlignedComponent>
            {isTradeIn
              ? <AlignedComponent align={isSmallViewPort ? 'left' : 'right'}>
                <VehicleInfoArgo id="test-tradein-vehicleinfo" heading='TRADEIN' />
              </AlignedComponent>
              : ''
            }
          </FlexBox>
        </Fragment>
      }
      {displayDealerList.length === 0 ? (
        <BlankDealerListArgo />
      ) : (
          <DealerList displayDealerList={displayDealerList} selectedDealers={selectedDealers} isSmallOrMediumViewPort={isSmallOrMediumViewPort} />
      )}
      {displayDealerList.length === 0 || pages === 1 ? (
        <EmptyComponent />
      ) : (
          <CacheProvider value={emotionCache} >
            <PaginationContainer align={isSmallOrMediumViewPort ? 'center' : 'left'}>
              <Pagination
                css={paginationStyles}
                pages={pages}
                currentPage={selectedPage}
                isDefault={false}
                isCompactEnabled={true}
                onChange={(nextPage) => { handlePageChange(nextPage); }}
                id='pagination'
              />
            </PaginationContainer>
          </CacheProvider>
      )}
      <EmptyComponent />
      <div>
        <Button
          name={'Step2Button'}
          id={'Step2Button'}
          buttonType='primary'
          text='Get My Free Quotes'
          onClick={(e) => {
            onButtonPress();
            onNextPageSelected(e, isKbb);
          }}
          width='100%'
          minimizeSize={false}
          disabled={false}
          href='#'
          target='_self'
          enableCapitalize={true}
          sizeType='regular'
        />
      </div>
    </FlexBox>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerSelectorArgo);